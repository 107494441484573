import React, {FC, useContext, useEffect, useState} from "react"

// Components
import { Button } from "@mantine/core"
import { AccountInfoPhoneFormGroup } from "./account_info_phone_form_group"
import { AccountInfoAddressFragment } from "./account_info_address_fragment"
import { UserContext } from "../usercontext"
import { TranslationContext } from "../translationcontext"

// API
import { GetLanguages } from "../api"

// Types
import { AccountInfoFormProps } from "./interfaces"
import { LanguagesAndCountries } from "../generated_api"


export const AccountInfoForm: FC<AccountInfoFormProps> = (
  {
    accountInfo, updateAccountInfo, saveAccountInfo, loading
  }) => {
  const {user, isBVUser, hasFullAccess} = useContext(UserContext)
  const translations = useContext(TranslationContext)
  const [availableLanguagesAndCountries, setAvailableLanguagesAndCountries] = useState<LanguagesAndCountries>({
    languages: [],
    countries: []
  })

  const FetchLanguages = async () => {
    let result = await GetLanguages()
    setAvailableLanguagesAndCountries(result)
  }

  useEffect(() => {
    FetchLanguages()
  }, [user])


  if (accountInfo === undefined) return null


  const update = (name: string, value: any) => {
    updateAccountInfo({
      ...accountInfo,
      [name]: value
    })
  }

  const handleChange = (event: any) => {
    update(event.currentTarget.name, event.currentTarget.value)
  }


  return (
    <div>
      <div className="vg-panel vg-panel-lg">
        <div className="vg-panel-content">
          <div className="vg-form-section">
            <div className="vg-form-section-title">
              {translations.common?.contactMe}
              <p className="text-muted">
                {translations.common?.contactMeSubtitle}
              </p>
            </div>
            <div className="vg-form-grid vg-grid-2">
              <div className="vg-form-group">
                <label>{translations.contact?.email}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={
                      /* TODO: How to reload the user context */
                      true
                    }
                    type="text"
                    name="email"
                    placeholder="jane.doe@example.com"
                    onBlur={handleChange}
                    defaultValue={accountInfo.email}
                  />
                </div>
              </div>
              <AccountInfoPhoneFormGroup
                loading={loading}
                phone={accountInfo.phone!}
                updateAccountInfo={update}
              />
            </div>
          </div>
          <div className="vg-form-section">
            <div className="vg-form-section-title">
              {translations.common?.aboutMe}
              <p className="text-muted">
                {translations.common?.aboutMeSubtitle}
              </p>
            </div>
            <div className="vg-form-grid vg-grid-2">
              <div className="vg-form-group">
                <label>{translations.common?.firstname}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="firstName"
                    placeholder="Jane"
                    onBlur={handleChange}
                    defaultValue={accountInfo.firstName}
                  />
                </div>
              </div>
              <div className="vg-form-group">
                <label>{translations.common?.lastname}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="lastName"
                    placeholder="Doe"
                    onBlur={handleChange}
                    defaultValue={accountInfo.lastName}
                  />
                </div>
              </div>
              <div className="vg-form-group">
                <label>{translations.contact?.organizationName}</label>
                <div>
                  <input
                    className="form-control form-control-lg"
                    disabled={loading}
                    type="text"
                    name="companyName"
                    onBlur={handleChange}
                    defaultValue={accountInfo.companyName}
                  />
                </div>
              </div>
              <AccountInfoAddressFragment
                allCountries={availableLanguagesAndCountries.countries!}
                loading={loading}
                address={accountInfo.address!}
                updateAccountInfo={update}
                language={accountInfo.language!}
              />
              <div className="vg-form-group">
                <label>{translations.common?.preferredLanguage}</label>
                <div>
                  <select
                    className="custom-select custom-select-lg"
                    disabled={loading}
                    name="language"
                    onChange={handleChange}
                    value={accountInfo.language}
                  >
                    {availableLanguagesAndCountries.languages?.map(l => {
                      return <option key={l.id} value={l.specificLCID} label={l.name}>{l.name}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vg-panel-footer">
          <Button
            disabled={loading || isBVUser && !hasFullAccess}
            onClick={saveAccountInfo}
            className="btn btn-lg btn-primary btn-rounded">
            {translations.common?.saveChanges}
          </Button>
        </div>
      </div>
    </div>
  )
}
