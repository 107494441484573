import {useEffect, useRef} from "react"
import {OwnerPortalTranslations, UserModel, UserProfile} from "./generated_api"

export function currencyFormatter(currency: string, value: number) {
    let formatter = new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: currency
    })

    return formatter.format(value)
}

export function upperCaseFirstCharacter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function groupOwnersByOrganizationId(objectArray: Array<UserProfile>) {
    let result: any[] = []

    //javascript array has a method foreach that enumerates keyvalue pairs.
    objectArray.forEach(
        r => {
            //if an array index by the value of id is not found, instantiate it.
            if (!result[r.accountInfo?.organizationId!]) {
                //result gets a new index of the value at id.
                result[r.accountInfo?.organizationId!] = []
            }
            //push that whole object from api_array into that list
            result[r.accountInfo!.organizationId!].push(r)
        }
    )

    return result
}

export function useIsMounted(): { current: boolean } {
    const componentIsMounted = useRef(true)
    useEffect(() => () => {
        componentIsMounted.current = false
    }, [])
    return componentIsMounted
}

export function isUser(user: UserModel): user is UserModel {
    return (user as UserModel)?.email !== undefined
}

export function isAdminUser(user: UserModel): user is UserModel {
    return !!(user as UserModel)?.bvUser
}

export const dotNetToJSDate = (dateTime: Date) => {
    const newDate = new Date(dateTime);
    const formattedDateString = newDate.toISOString().split("T")[0];
    const [year, month, day] = formattedDateString.split("-").map(Number);

    // Months are zero-indexed in JavaScript, so we subtract 1 from the month
    return new Date(year, month - 1, day);
}

export const getMonthDate = (selectedMonth: string) => {
    //selectedMonth should be something like 2023-11
    const [year, cmonth, _] = selectedMonth.split("-").map(Number);
    return new Date(year, cmonth - 1, 1);
}

export function monthNumberToString(monthNumber: number, translations: OwnerPortalTranslations) {
    switch (monthNumber) {
        case 0:
            return translations.availability?.january
        case 1:
            return translations.availability?.february
        case 2:
            return translations.availability?.march
        case 3:
            return translations.availability?.april
        case 4:
            return translations.availability?.may
        case 5:
            return translations.availability?.june
        case 6:
            return translations.availability?.july
        case 7:
            return translations.availability?.august
        case 8:
            return translations.availability?.september
        case 9:
            return translations.availability?.october
        case 10:
            return translations.availability?.november
        case 11:
            return translations.availability?.december
    }
}

export function getDaysInMonthUTC(month: number, year: number) {
    let date = new Date(Date.UTC(year, month, 1))
    let lastm = month === 0 ? 11 : month - 1
    let nextm = month === 11 ? 0 : month + 1
    let lastMonthDate = new Date(Date.UTC(month === 0 ? year - 1 : year, lastm, 1))
    let nextMonthDate = new Date(Date.UTC(month === 11 ? year + 1 : year, nextm, 1))

    let days = []
    while (date.getUTCMonth() <= month && !(date.getUTCMonth() === 0 && month === 11)) {
        days.push(new Date(date))
        date.setUTCDate(date.getUTCDate() + 1)
    }

    let daysLastMonth = []
    while (lastMonthDate.getUTCMonth() <= lastm && !(lastMonthDate.getUTCMonth() === 0 && lastm === 11)) {
        daysLastMonth.push(new Date(lastMonthDate))
        lastMonthDate.setUTCDate(lastMonthDate.getUTCDate() + 1)
    }

    let daysNextMonth = []
    while (nextMonthDate.getUTCMonth() <= nextm && !(nextMonthDate.getUTCMonth() === 0 && nextm === 11)) {
        daysNextMonth.push(new Date(nextMonthDate))
        nextMonthDate.setUTCDate(nextMonthDate.getUTCDate() + 1)
    }

    return [days, daysLastMonth, daysNextMonth]
}

export function getDaysInOneMonth(month: number, year: number) {
    let date = new Date(Date.UTC(year, month, 1))

    let days = []
    while (date.getUTCMonth() <= month && !(date.getUTCMonth() === 0 && month === 11)) {
        days.push(new Date(date))
        date.setUTCDate(date.getUTCDate() + 1)
    }
    return days
}

export function getDaysBetweenDates(startDate: Date, endDate: Date) {
    let days = []
    let date = new Date(startDate)
    while (date < new Date(endDate)) {
        days.push(new Date(date))
        date.setUTCDate(date.getUTCDate() + 1)
    }
    return days
}


export const getWeekNumber = (dt: Date) => {
    let date = new Date(dt.getTime())
    date.setHours(0, 0, 0, 0)
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
    // January 4 is always in week 1.
    let week1 = new Date(date.getFullYear(), 0, 4)
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7)
}